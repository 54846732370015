var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Cookies from 'js-cookie';
import moment from 'moment';
import { BeatLoader } from 'react-spinners';
import { usePDF } from '@react-pdf/renderer';
import { AdminLayout } from 'src/Layouts';
import useIdParam from 'src/hooks/useIdParam';
import { COMMON_EXPENSES_RECEIPTS, STATE, TYPE_CONTACT, BUILDING_API, APARTMENT, CLIENT_NUMBER, EXPIRATION_DATE, PENDING, COMMON_EXPENSES_API, SELECT, DATE_FORMAT_MOMENT, RECEIPT_KEY, PENDING_KEY, IN_PROGRESS_KEY, IN_PROGRESS, INFORMED, INFORMED_KEY, EXPIRED_STATUS, MODIFIED_BY, PAYMENT_STATUS, ACTIONS, } from 'src/Utils/constants';
import { PaymentModal } from 'src/Components';
import { PdfComponent } from 'src/Components/ReceiptForm';
import { downloadPDF, selectedAll, theme } from 'src/Utils';
import { getData } from 'src/Utils/api';
import { Container, Title } from './styles';
import FilterTable from './FilterTable';
import { RelativeDiv } from '../BillsPage/styles';
import CreatePDF from './CreatePDF';
import { submitPDF } from './duck';
import Modal from 'src/Components/Common/Modal/Modal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setTowerList } from '../../../redux/slices/commonExpensesReceiptSlice';
import { setFormSelected, setSelectedApartment, setSelectedBuilding, setSelectedEndDate, setSelectedStartDate, setSelectedTower, } from '../../../redux/slices/tableFiltersSlice';
var getStatus = function (status, expired, isSuperAdmin) {
    if (!isSuperAdmin && (status === IN_PROGRESS_KEY || status === INFORMED_KEY)) {
        return PAYMENT_STATUS;
    }
    if (status === PENDING_KEY) {
        if (expired) {
            return EXPIRED_STATUS;
        }
        return PENDING;
    }
    if (status === IN_PROGRESS_KEY) {
        return IN_PROGRESS;
    }
    if (status === INFORMED_KEY) {
        return INFORMED;
    }
    return PENDING;
};
function createBuilding(label, value, rows, code) {
    return { label: label, value: value, rows: rows, code: code };
}
function createData(first, second, third, fourth, fifth, pdf, dots, ID, receiptNumber, address, amount, description, towerId, apartment_id, expired, building) {
    return {
        first: first,
        second: second,
        third: third,
        fourth: fourth,
        fifth: fifth,
        pdf: pdf,
        dots: dots,
        ID: ID,
        receiptNumber: receiptNumber,
        address: address,
        amount: amount,
        description: description,
        towerId: towerId,
        apartment_id: apartment_id,
        building: building,
        expired: expired,
    };
}
var CommonExpensesPage = function () {
    var dispatch = useAppDispatch();
    var _a = useAppSelector(function (state) { return state.tableFiltersSlice; }), selectedBuilding = _a.selectedBuilding, selectedTower = _a.selectedTower, selectedApartment = _a.selectedApartment, startDate = _a.startDate, endDate = _a.endDate, formSelected = _a.formSelected;
    var towerList = useAppSelector(function (state) { return state.commonExpensesReceiptSlice; }).towerList;
    var _b = useState(), buildingList = _b[0], setBuildingList = _b[1];
    var _c = useState([]), rows = _c[0], setRows = _c[1];
    var _d = useState(false), refetch = _d[0], setRefetch = _d[1];
    var _e = useState(false), openModalContacts = _e[0], setOpenModalContacts = _e[1];
    var isContact = Cookies.get('User') === TYPE_CONTACT;
    var isSuperAdmin = Cookies.get('SuperAdmin') === 'true';
    var _f = useState(), pdfData = _f[0], setPdfData = _f[1];
    var _g = useState(), selected = _g[0], setSelected = _g[1];
    var _h = useState(), selectedPDF = _h[0], setSelectedPDF = _h[1];
    var _j = useState(false), isOpenModal = _j[0], setIsOpenModal = _j[1];
    var headCells = !isSuperAdmin
        ? [
            {
                id: 'first',
                numeric: true,
                disablePadding: false,
                label: APARTMENT,
            },
            {
                id: 'second',
                numeric: true,
                disablePadding: false,
                label: CLIENT_NUMBER,
            },
            {
                id: 'third',
                numeric: true,
                disablePadding: false,
                label: EXPIRATION_DATE,
            },
            {
                id: 'fourth',
                numeric: true,
                disablePadding: false,
                label: STATE,
            },
            {
                id: 'fifth',
                numeric: true,
                disablePadding: false,
                label: '',
            },
            {
                id: 'pdf',
                numeric: true,
                disablePadding: false,
                label: '',
            },
            {
                id: 'dots',
                numeric: true,
                disablePadding: false,
                label: '',
            },
            {
                id: 'dots',
                numeric: true,
                disablePadding: false,
                label: '',
            },
        ]
        : [
            {
                id: 'first',
                numeric: true,
                disablePadding: false,
                label: APARTMENT,
            },
            {
                id: 'second',
                numeric: true,
                disablePadding: false,
                label: CLIENT_NUMBER,
            },
            {
                id: 'third',
                numeric: true,
                disablePadding: false,
                label: EXPIRATION_DATE,
            },
            {
                id: 'fourth',
                numeric: true,
                disablePadding: false,
                label: STATE,
            },
            {
                id: 'fifth',
                numeric: true,
                disablePadding: false,
                label: MODIFIED_BY,
            },
            {
                id: 'pdf',
                numeric: true,
                disablePadding: false,
                label: ACTIONS,
            },
            {
                id: 'dots',
                numeric: true,
                disablePadding: false,
                label: '',
            },
            {
                id: 'dots',
                numeric: true,
                disablePadding: false,
                label: '',
            },
        ];
    var PDFdocument = (React.createElement(PdfComponent, { clientNumber: (pdfData === null || pdfData === void 0 ? void 0 : pdfData.clientNumber) || '', location: (pdfData === null || pdfData === void 0 ? void 0 : pdfData.location) || '', receiptNumber: (pdfData === null || pdfData === void 0 ? void 0 : pdfData.receiptNumber) || '', expirationDate: (pdfData === null || pdfData === void 0 ? void 0 : pdfData.expirationDate) || '', totalAmount: (pdfData === null || pdfData === void 0 ? void 0 : pdfData.totalAmount) || '', holder: (pdfData === null || pdfData === void 0 ? void 0 : pdfData.holder) || '', issueDate: (pdfData === null || pdfData === void 0 ? void 0 : pdfData.issueDate) || '', description: (pdfData === null || pdfData === void 0 ? void 0 : pdfData.description) || '', currency: (pdfData === null || pdfData === void 0 ? void 0 : pdfData.currency) || '' }));
    var _k = usePDF({ document: PDFdocument }), instance = _k[0], updateInstance = _k[1];
    var handleSetTower = function (towerToSelect) {
        dispatch(setSelectedTower(towerToSelect));
        dispatch(setFormSelected(false));
        setQueryParamUsed(true);
    };
    var handleSetApartment = function (apartmentToSelect) {
        return dispatch(setSelectedApartment(apartmentToSelect));
    };
    var deleteRow = function (id) { return setRows(rows.filter(function (_a) {
        var ID = _a.ID;
        return ID !== id;
    })); };
    useEffect(function () {
        pdfData && updateInstance();
    }, [pdfData]);
    useEffect(function () {
        if (!instance.loading && selected) {
            var file = new File([instance.blob], RECEIPT_KEY + "-" + (pdfData === null || pdfData === void 0 ? void 0 : pdfData.receiptNumber) + ".pdf", {
                type: 'application/pdf',
            });
            mutatePDF({ file: file, receiptId: selected });
        }
    }, [instance.loading]);
    useQuery([selectedPDF], function () { return getData("/common_expenses_receipts/" + selectedPDF); }, {
        onSuccess: function (_a) {
            var _b;
            var numeric_client_code = _a.numeric_client_code, address = _a.address, receipt_number = _a.receipt_number, expiration_date = _a.expiration_date, amount = _a.amount, description = _a.description, currency = _a.currency;
            selectedBuilding &&
                setPdfData({
                    clientNumber: numeric_client_code,
                    location: address,
                    receiptNumber: receipt_number,
                    expirationDate: moment(expiration_date).format(DATE_FORMAT_MOMENT),
                    totalAmount: amount,
                    holder: (_b = selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.label) === null || _b === void 0 ? void 0 : _b.toString(),
                    issueDate: moment(new Date()).format(DATE_FORMAT_MOMENT),
                    description: description,
                    currency: currency,
                });
        },
        enabled: selectedPDF,
        retry: false,
        refetchOnWindowFocus: false,
    });
    var isLoadingBuildings = useQuery([BUILDING_API], function () { return getData("/buildings"); }, {
        onSuccess: function (data) {
            var buildings = data.buildings.map(function (_a) {
                var name = _a.name, id = _a.id, code = _a.code;
                return createBuilding(name, id, rows, code);
            });
            buildings.sort(function (a, b) {
                return a.label.localeCompare(b.label, 'es');
            });
            setBuildingList(buildings);
        },
        retry: false,
        refetchOnWindowFocus: false,
    }).isLoading;
    var _l = useQuery([selectedBuilding], function () { return getData("/buildings/" + (selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value) + "/towers"); }, {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: selectedBuilding,
    }), dataTowers = _l.data, isLoadingTowers = _l.isLoading;
    var towerData = useMemo(function () { var _a; return (_a = dataTowers === null || dataTowers === void 0 ? void 0 : dataTowers.towers) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
        var id = _a.id, name = _a.name, code = _a.code;
        return ({
            label: name,
            value: id,
            code: code,
        });
    }); }, [dataTowers]);
    var _m = useQuery([selectedTower], function () {
        return getData("/buildings/" + (selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value) + "/apartments/dropdown?tower=" + (selectedTower === null || selectedTower === void 0 ? void 0 : selectedTower.value));
    }, {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: selectedTower,
    }), dataApartments = _m.data, isLoadingApartments = _m.isLoading;
    var apartmentData = useMemo(function () {
        var _a;
        return __spreadArrays([
            selectedAll
        ], (((_a = dataApartments === null || dataApartments === void 0 ? void 0 : dataApartments.apartments) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
            var id = _a.id, number = _a.number;
            return ({
                label: number,
                value: id,
            });
        })) || []));
    }, [dataApartments]);
    var mutatePDF = useMutation(submitPDF, {
        onSuccess: function () {
            (instance === null || instance === void 0 ? void 0 : instance.url) &&
                downloadPDF(instance.url, RECEIPT_KEY + "-" + (pdfData === null || pdfData === void 0 ? void 0 : pdfData.receiptNumber) + ".pdf");
            setSelected(undefined);
            setPdfData(undefined);
            setRefetch(function (prev) { return !prev; });
        },
    })[0];
    var _o = useQuery([
        COMMON_EXPENSES_API,
        selectedBuilding,
        selectedTower,
        selectedApartment,
        startDate,
        endDate,
        refetch,
    ], function () {
        return getData("/common_expenses_receipts", {
            building_id: selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value,
            tower_id: selectedTower === null || selectedTower === void 0 ? void 0 : selectedTower.value,
            apartment_number: (selectedApartment === null || selectedApartment === void 0 ? void 0 : selectedApartment.label) === 'Todos'
                ? ''
                : selectedApartment === null || selectedApartment === void 0 ? void 0 : selectedApartment.label,
            start_date: startDate
                ? moment(startDate).format(DATE_FORMAT_MOMENT)
                : null,
            end_date: endDate ? moment(endDate).format(DATE_FORMAT_MOMENT) : null,
        });
    }, {
        onSuccess: function (data) {
            data.common_expenses_receipts.sort(function (a, b) {
                return moment(b.created_at).diff(moment(a.created_at));
            });
            if (!data.common_expenses_receipts[0])
                setRows([]);
            else if (!isContact && !isSuperAdmin) {
                setRows(data.common_expenses_receipts.map(function (_a) {
                    var apartment_number = _a.apartment_number, expiration_date = _a.expiration_date, status = _a.status, id = _a.id, pdf = _a.pdf, receipt_number = _a.receipt_number, address = _a.address, amount = _a.amount, description = _a.description, tower = _a.tower, apartment_id = _a.apartment_id, numeric_client_code = _a.numeric_client_code, _b = _a.expired, expired = _b === void 0 ? false : _b;
                    return createData(apartment_number.toString() || '-', numeric_client_code, moment(expiration_date).format(DATE_FORMAT_MOMENT), getStatus(status, expired, isSuperAdmin), '', pdf, '', id, receipt_number, address, amount, description, tower, apartment_id, expired);
                }));
            }
            else if (!isSuperAdmin) {
                setRows(data.common_expenses_receipts.map(function (_a) {
                    var apartment_number = _a.apartment_number, expiration_date = _a.expiration_date, status = _a.status, pdf = _a.pdf, id = _a.id, receipt_number = _a.receipt_number, address = _a.address, amount = _a.amount, description = _a.description, tower = _a.tower, apartment_id = _a.apartment_id, numeric_client_code = _a.numeric_client_code, _b = _a.expired, expired = _b === void 0 ? false : _b;
                    return createData(apartment_number.toString() || '-', numeric_client_code, moment(expiration_date).format(DATE_FORMAT_MOMENT), getStatus(status, expired, isSuperAdmin), '', pdf, '', id, receipt_number, address, amount, description, tower, apartment_id, expired, selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.label);
                }));
            }
            else {
                setRows(data.common_expenses_receipts.map(function (_a) {
                    var apartment_number = _a.apartment_number, expiration_date = _a.expiration_date, status = _a.status, id = _a.id, modified_by = _a.modified_by, pdf = _a.pdf, receipt_number = _a.receipt_number, address = _a.address, amount = _a.amount, description = _a.description, tower = _a.tower, apartment_id = _a.apartment_id, numeric_client_code = _a.numeric_client_code, _b = _a.expired, expired = _b === void 0 ? false : _b;
                    return createData(apartment_number.toString() || '-', numeric_client_code, moment(expiration_date).format(DATE_FORMAT_MOMENT), getStatus(status, expired, isSuperAdmin), modified_by, pdf, '', id, receipt_number, address, amount, description, tower, apartment_id, expired, selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.label);
                }));
            }
        },
        enabled: selectedBuilding,
        refetchOnWindowFocus: false,
    }), isLoadingCommonExpenses = _o.isLoading, refetchExpenses = _o.refetch;
    var _p = useIdParam({
        buildingList: buildingList,
        setSelectedBuilding: function (building) {
            return dispatch(setSelectedBuilding(building));
        },
        towerList: towerData,
        setSelectedTower: function (towerToSelect) {
            return dispatch(setSelectedTower(towerToSelect));
        },
        apartmentList: (dataApartments === null || dataApartments === void 0 ? void 0 : dataApartments.apartments) || [],
        setSelectedApartment: handleSetApartment,
        rows: rows,
        setRows: setRows,
        idRowAttribute: 'ID',
        refetch: refetchExpenses,
    }), isLoading = _p.isLoading, handleSetBuilding = _p.handleBuildingChange, shouldUseIdParam = _p.shouldUseIdParam, setQueryParamUsed = _p.setQueryParamUsed;
    useEffect(function () {
        dispatch(setTowerList(towerData));
    }, [towerData]);
    useEffect(function () {
        if (!formSelected && !shouldUseIdParam) {
            dispatch(setSelectedTower(towerList === null || towerList === void 0 ? void 0 : towerList[0]));
            dispatch(setSelectedApartment(selectedAll));
        }
    }, [towerList]);
    useEffect(function () {
        if (!formSelected && !shouldUseIdParam)
            dispatch(setSelectedApartment(selectedAll));
    }, [apartmentData]);
    return (React.createElement(AdminLayout, null,
        React.createElement(PaymentModal, { isOpen: isOpenModal, setIsOpen: setIsOpenModal, id: selected }),
        React.createElement(RelativeDiv, null,
            React.createElement(Container, null,
                React.createElement(Title, null, COMMON_EXPENSES_RECEIPTS),
                isLoadingBuildings && (React.createElement(BeatLoader, { size: "8px", color: theme.palette.black[100] })),
                buildingList && (React.createElement(FilterTable, { rows: rows, building: selectedBuilding || { label: SELECT, value: '' }, headCells: headCells, buildingList: buildingList, towerList: towerData, apartmentList: apartmentData, selectedBuilding: selectedBuilding, setSelectedBuilding: function (buildingToSelect) {
                        handleSetBuilding(buildingToSelect);
                        dispatch(setFormSelected(false));
                        setQueryParamUsed(true);
                    }, selectedTower: selectedTower, setSelectedTower: handleSetTower, selectedApartment: selectedApartment, setSelectedApartment: handleSetApartment, startDate: startDate, setStartDate: function (startDate) {
                        return dispatch(setSelectedStartDate(startDate));
                    }, endDate: endDate, setEndDate: function (endDate) {
                        return dispatch(setSelectedEndDate(endDate));
                    }, addPDF: CreatePDF, isLoading: isLoadingBuildings ||
                        isLoadingTowers ||
                        isLoadingApartments ||
                        isLoadingCommonExpenses ||
                        isLoading, hasSearch: false, setRefetch: setRefetch, setSelectedId: setSelected, setSelectedPDF: setSelectedPDF, setIsOpenModal: setIsOpenModal, orderByStateIndex: isContact ? 'third' : 'fourth', isPaymentHistory: true, hasButton: true, deleteRow: deleteRow, setOpenModalContacts: setOpenModalContacts })))),
        React.createElement(Modal, { isOpen: openModalContacts, setIsOpen: setOpenModalContacts, isAssociatedContacts: true })));
};
export default CommonExpensesPage;
