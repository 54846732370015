var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M, S } from '../../Utils';
import { Snackbar } from '@material-ui/core';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  min-height: 100vh;\n  height: 100%;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0px 5%;\n  flex-direction: column;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    padding: 0px 28px;\n  }\n"], ["\n  display: flex;\n  min-height: 100vh;\n  height: 100%;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0px 5%;\n  flex-direction: column;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    padding: 0px 28px;\n  }\n"])), theme.palette.admin.primary[50], M);
export var InnerContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  gap: 20px;\n  width: 100%;\n  justify-content: flex-start;\n"], ["\n  display: flex;\n  gap: 20px;\n  width: 100%;\n  justify-content: flex-start;\n"])));
export var CheckboxesContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  max-height: 280px;\n  overflow-y: scroll;\n\n  /* Estilos del scrollbar dentro de CheckboxesContainer */\n  overflow: -moz-scrollbars-vertical;\n  &::-webkit-scrollbar {\n    -webkit-appearance: none;\n    width: 7px;\n  }\n  &::-webkit-scrollbar-thumb {\n    border-radius: 4px;\n    background-color: rgba(0, 0, 0, 0.5);\n    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  max-height: 280px;\n  overflow-y: scroll;\n\n  /* Estilos del scrollbar dentro de CheckboxesContainer */\n  overflow: -moz-scrollbars-vertical;\n  &::-webkit-scrollbar {\n    -webkit-appearance: none;\n    width: 7px;\n  }\n  &::-webkit-scrollbar-thumb {\n    border-radius: 4px;\n    background-color: rgba(0, 0, 0, 0.5);\n    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);\n  }\n"])));
export var Title = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 24px;\n  font-family: ", ";\n  width: 100%;\n  margin-bottom: 30px;\n  margin-top: 5%;\n  margin-left: -20px;\n  background-color: ", ";\n  color: ", ";\n  @media (max-width: ", "px) {\n    margin-left: 0px;\n    font-size: 18px;\n    font-family: ", ";\n    color: ", ";\n  }\n"], ["\n  font-size: 24px;\n  font-family: ", ";\n  width: 100%;\n  margin-bottom: 30px;\n  margin-top: 5%;\n  margin-left: -20px;\n  background-color: ", ";\n  color: ", ";\n  @media (max-width: ", "px) {\n    margin-left: 0px;\n    font-size: 18px;\n    font-family: ", ";\n    color: ", ";\n  }\n"])), theme.typography.bold.ralewayBold, theme.palette.admin.primary[50], theme.palette.admin.primary[75], M, theme.typography.OpenSans, theme.palette.black[100]);
export var BoxContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: 25px;\n  display: flex;\n  flex-direction: column;\n  border-radius: 7px;\n  width: 386px;\n"], ["\n  background-color: ", ";\n  padding: 25px;\n  display: flex;\n  flex-direction: column;\n  border-radius: 7px;\n  width: 386px;\n"])), theme.palette.admin.primary[45]);
export var InputContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 100px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  &.height {\n    min-height: 50px;\n  }\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n"], ["\n  height: 100px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  &.height {\n    min-height: 50px;\n  }\n  @media (max-width: ", "px) {\n    width: auto;\n  }\n"])), S);
export var Label = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 14px;\n  margin-bottom: 5px;\n  color: ", ";\n  width: 100%;\n  &.extra {\n    margin-bottom: 8px;\n  }\n"], ["\n  font-size: 14px;\n  margin-bottom: 5px;\n  color: ", ";\n  width: 100%;\n  &.extra {\n    margin-bottom: 8px;\n  }\n"])), theme.palette.black[100]);
export var Text = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 14px;\n  color: ", ";\n  font-weight: 700;\n"], ["\n  font-family: ", ";\n  font-size: 14px;\n  color: ", ";\n  font-weight: 700;\n"])), theme.typography.bold.ralewayBold, theme.palette.black[60]);
export var TextContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  margin-top: 20px;\n  padding: 0px 12px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  margin-top: 20px;\n  padding: 0px 12px;\n"])));
export var Div = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  font-size: 14px;\n  font-family: ", ";\n  &.row {\n    flex-direction: row;\n  }\n  &.column {\n    flex-direction: column;\n  }\n  &.height {\n    height: 20px;\n    align-self: center;\n  }\n  &.counter {\n    height: 43px;\n    background-color: ", ";\n    border-radius: 9px;\n    padding: 0px 8px;\n  }\n  &.spaced {\n    margin-right: 8px;\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  font-size: 14px;\n  font-family: ", ";\n  &.row {\n    flex-direction: row;\n  }\n  &.column {\n    flex-direction: column;\n  }\n  &.height {\n    height: 20px;\n    align-self: center;\n  }\n  &.counter {\n    height: 43px;\n    background-color: ", ";\n    border-radius: 9px;\n    padding: 0px 8px;\n  }\n  &.spaced {\n    margin-right: 8px;\n    width: 100%;\n  }\n"])), theme.typography.normal.raleway, theme.palette.white[100]);
export var Error = styled.label(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  color: ", ";\n  align-self: flex-start;\n  font-size: 12px;\n  margin-top: 10px;\n  height: 20px;\n  @media (max-width: ", "px) {\n    align-self: center;\n    align-items: center;\n  }\n"], ["\n  color: ", ";\n  align-self: flex-start;\n  font-size: 12px;\n  margin-top: 10px;\n  height: 20px;\n  @media (max-width: ", "px) {\n    align-self: center;\n    align-items: center;\n  }\n"])), theme.palette.error[50], S);
export var ButtonsContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin-top: auto;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin-top: auto;\n"])));
export var ButtonsRow = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 0px;\n  padding: 25px 40px;\n  background-color: ", ";\n  border-radius: 8px;\n  justify-content: center;\n  @media (max-width: ", "px) {\n    padding-right: 20px;\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 0px;\n  padding: 25px 40px;\n  background-color: ", ";\n  border-radius: 8px;\n  justify-content: center;\n  @media (max-width: ", "px) {\n    padding-right: 20px;\n    background-color: ", ";\n  }\n"])), theme.palette.admin.primary[45], M, theme.palette.admin.primary[50]);
export var Link = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  font-size: 14px;\n  text-decoration-line: underline;\n  cursor: pointer;\n  margin-right: 50px;\n  color: ", ";\n  &.margin-right {\n    margin-right: 16px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n"], ["\n  font-size: 14px;\n  text-decoration-line: underline;\n  cursor: pointer;\n  margin-right: 50px;\n  color: ", ";\n  &.margin-right {\n    margin-right: 16px;\n  }\n  @media (max-width: ", "px) {\n    font-size: 12px;\n  }\n"])), theme.palette.admin.primary[75], M);
export var SuccessSnackbar = styled(Snackbar)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  .MuiSnackbarContent-root {\n    background-color: ", ";\n  }\n"], ["\n  .MuiSnackbarContent-root {\n    background-color: ", ";\n  }\n"])), theme.palette.green);
export var ScheduleInfo = styled.p(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  font-size: 11px;\n  margin-top: -20px;\n  margin-bottom: 20px;\n"], ["\n  font-size: 11px;\n  margin-top: -20px;\n  margin-bottom: 20px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
