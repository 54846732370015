import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AMENITIES_NOT_AVAILABLE, AMENITY, AMENITY_KEY, APT, APT_KEY, BUILDING, BUILDING_KEY, CLIENT_CODE, CLIENT_KEY, RESERVATION_DATE, SCHEDULE, SCHEDULES_NOT_AVAILABLE, TIME_KEY, } from 'src/Utils';
import { InputComponent, Popover, SelectComponent, } from 'src/Components/Common';
import { openedArrow } from 'src/assets/Images';
import CalendarComponent from 'src/Components/Common/Calendar/CalendarComponent';
import { CustomInput } from 'src/Components/ReceiptForm';
import useClientCode from './hooks/useClientCode';
import { API_DATA } from 'src/Utils/mockData';
import { useAmenitiesAndSchedules } from './hooks/useAmenitiesAndSchedule';
import { BoxContainer, InputContainer, Label, ScheduleInfo } from '../styles';
import { useAppSelector } from '../../../redux/hooks';
import TimeSelectors from 'src/Components/TimeSelectors';
import { scheduleNeedsTimeInputs, handleClientCodeChange, timePerHour, getTimeLimitText } from '../utils';
export var ReservationForm = function () {
    var _a;
    var _b = useAppSelector(function (state) { return state.tableFiltersSlice; }), selectedBuilding = _b.selectedBuilding, selectedTower = _b.selectedTower, selectedApartment = _b.selectedApartment;
    var _c = useFormContext(), setValue = _c.setValue, watch = _c.watch;
    var _d = watch(), amenity = _d.amenity, clientCode = _d.clientCode, date = _d.date, time = _d.time;
    var _e = useState({
        label: '',
        value: '',
    }), placeData = _e[0], setPlaceData = _e[1];
    var _f = useState(null), anchorRefAmenity = _f[0], setAnchorRefAmenity = _f[1];
    var _g = useState(null), anchorRefTime = _g[0], setAnchorRefTime = _g[1];
    var _h = useState(null), anchorRefApt = _h[0], setAnchorRefApt = _h[1];
    useClientCode({
        selectedApartment: selectedApartment,
        selectedBuilding: selectedBuilding,
        selectedTower: selectedTower,
        setClientCode: handleClientCodeChange(clientCode, setValue),
        placeData: placeData,
    });
    var _j = useAmenitiesAndSchedules({
        selectedBuilding: selectedBuilding,
        amenity: amenity,
        date: date,
        setExtras: function (extras) { return setValue('extras', extras); },
    }), amenities = _j.amenities, schedules = _j.schedules;
    var handleOutsideClick = function () {
        setAnchorRefAmenity(null);
        setAnchorRefTime(null);
        setAnchorRefApt(null);
    };
    var isAvailable = function (date) {
        var _a;
        return !!((_a = amenity === null || amenity === void 0 ? void 0 : amenity.availability) === null || _a === void 0 ? void 0 : _a.length)
            ? amenity === null || amenity === void 0 ? void 0 : amenity.availability[date.getDay() === 0 ? 6 : date.getDay() - 1] : true;
    };
    return (React.createElement(BoxContainer, null,
        React.createElement(InputComponent, { disabled: true, placeHolder: "A01L0001P", type: CLIENT_KEY, data: clientCode, error: {}, label: CLIENT_CODE, onChangeFunction: null }),
        React.createElement(InputComponent, { type: BUILDING_KEY, data: (_a = selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.label) === null || _a === void 0 ? void 0 : _a.toString(), error: {}, label: BUILDING, onChangeFunction: function () { return null; }, disabled: true }),
        React.createElement(SelectComponent, { label: APT, type: APT_KEY, data: placeData.label, errors: {}, img: openedArrow, anchorRef: anchorRefApt, setAnchorRef: setAnchorRefApt }),
        React.createElement(Popover, { data: API_DATA, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefApt, setSelected: setPlaceData, isFilter: false, className: "large-form" }),
        React.createElement(SelectComponent, { label: AMENITY, type: AMENITY_KEY, data: !amenities.length ? AMENITIES_NOT_AVAILABLE : amenity.label, errors: {}, img: openedArrow, anchorRef: anchorRefAmenity, setAnchorRef: setAnchorRefAmenity }),
        !!amenities.length && (React.createElement(Popover, { data: amenities, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefAmenity, setSelected: function (amenity) { return setValue('amenity', amenity); }, isFilter: false, className: "large-form" })),
        React.createElement(InputContainer, { className: "height" },
            React.createElement(Label, null, RESERVATION_DATE),
            React.createElement(CalendarComponent, { selected: date, onChange: function (e) { return setValue('date', e); }, customInput: React.createElement(CustomInput, null), filterDate: isAvailable })),
        React.createElement(SelectComponent, { label: SCHEDULE, type: TIME_KEY, data: amenity.label && !schedules.length
                ? SCHEDULES_NOT_AVAILABLE
                : time.label, errors: {}, img: openedArrow, anchorRef: anchorRefTime, setAnchorRef: setAnchorRefTime }),
        !!schedules.length && (React.createElement(Popover, { data: schedules, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefTime, setSelected: function (time) { return setValue('time', time); }, isFilter: false, className: "large-form" })),
        timePerHour(time) && React.createElement(ScheduleInfo, null, "La reserva en este horario tiene un precio de " + time.price + " por hora."),
        scheduleNeedsTimeInputs(time, amenity) && (React.createElement(React.Fragment, null,
            React.createElement(ScheduleInfo, null, getTimeLimitText(amenity)),
            React.createElement(TimeSelectors, null)))));
};
