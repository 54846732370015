import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import Cookies from 'js-cookie';
import moment from 'moment';
import { useHistory } from 'react-router';
import { AdminLayout } from 'src/Layouts';
import { Container, TopContainer, Title } from './styles';
import { TYPE_CONTACT, SETTELMENTS, RECEIPTS, SETTLEMENTS_API, COMMON_EXPENSES_API, ALERTS_API, LAST_BUILDINGS_API, ROUTES, WELCOME, PENDING_KEY, M, DOWNLOAD_APP_LINK_APPLE, DOWNLOAD_APP_LINK_ANDROID, APP_MODAL, } from 'src/Utils/constants';
import { Button, DashboardCard } from 'src/Components/Common';
import { AdminDashboard, Alerts, PaymentModal, SuperAdminDashboard, } from 'src/Components';
import { getData } from 'src/Utils/api';
import { createAlert } from './services';
import Modal from 'react-modal';
import { useWindowWidth } from 'src/useWindowWidth';
var HomeAdminPage = function () {
    var history = useHistory();
    var isContact = Cookies.get('User') === TYPE_CONTACT;
    var isSuperAdmin = Cookies.get('SuperAdmin') === 'true';
    var _a = useState(''), name = _a[0], setName = _a[1];
    var _b = useState([]), buildings = _b[0], setBuildings = _b[1];
    var _c = useState(), settlements = _c[0], setSettlements = _c[1];
    var _d = useState(), pendingExpenses = _d[0], setPendingExpenses = _d[1];
    var _e = useState([]), newAlerts = _e[0], setNewAlerts = _e[1];
    var _f = useState([]), oldAlerts = _f[0], setOldAlerts = _f[1];
    var _g = useState(), selectedReceipt = _g[0], setSelectedReceipt = _g[1];
    var _h = useState(false), isOpenModal = _h[0], setIsOpenModal = _h[1];
    var _j = useState(false), appModalIsOpen = _j[0], setAppModalIsOpen = _j[1];
    var windowWidth = useWindowWidth();
    var isMobile = windowWidth < M;
    var _k = useMutation(createAlert), mutateCreateAlert = _k[0], isSuccess = _k[1].isSuccess;
    useQuery(LAST_BUILDINGS_API, function () { return getData("/buildings/last_created"); }, {
        onSuccess: function (response) {
            setBuildings(response.buildings.map(function (_a) {
                var name = _a.name, id = _a.id, address = _a.address, quantity_of_apartments = _a.quantity_of_apartments;
                return {
                    name: name,
                    id: id,
                    address: address,
                    quantity_of_apartments: quantity_of_apartments,
                };
            }));
        },
        enabled: !isContact && !isSuperAdmin,
        refetchOnWindowFocus: false,
    });
    useQuery(SETTLEMENTS_API, function () { return getData("/settlements"); }, {
        onSuccess: function (response) {
            setSettlements(response.settlements.map(function (settlement) {
                return {
                    building: settlement.building,
                    dateRange: moment(settlement.start_date).format('DD/MM/YYYY') + " - " + moment(settlement.end_date).format('DD/MM/YYYY'),
                    pdf: settlement.pdf,
                    pdfName: settlement.document_name,
                    information: settlement.information,
                    start_date: settlement.start_date,
                    end_date: settlement.end_date,
                    id: settlement.id,
                    expired: false,
                };
            }));
        },
        enabled: isContact,
        refetchOnWindowFocus: false,
    });
    useQuery(COMMON_EXPENSES_API, function () { return getData("/common_expenses_receipts/last_month"); }, {
        onSuccess: function (response) {
            setPendingExpenses(response.common_expenses_receipts.map(function (receipt) { return ({
                building: receipt.building_name,
                dateRange: moment(receipt.expiration_date).format('DD/MM/YYYY'),
                currency: receipt.currency === 'dolares' ? 'US$' : '$',
                amount: receipt.amount,
                id: receipt.id,
                default_bank: receipt.default_bank,
                payed: receipt.status !== PENDING_KEY,
                expired: receipt.expired,
            }); }));
        },
        enabled: isContact,
        refetchOnWindowFocus: false,
    });
    var isLoadingAlerts = useQuery(ALERTS_API, function () { return getData("/alerts"); }, {
        onSuccess: function (response) {
            setNewAlerts(response.new_alerts);
            setOldAlerts(response.old_alerts);
        },
        refetchOnWindowFocus: false,
        enabled: isSuccess,
    }).isLoading;
    var _l = useQuery('me', function () {
        return getData("/users/profile");
    }), data = _l.data, gotData = _l.isSuccess;
    useEffect(function () {
        isContact && mutateCreateAlert();
    }, []);
    useEffect(function () {
        gotData && setName(data.name);
    }, [data, gotData]);
    useEffect(function () {
        var hasSeenModal = window.localStorage.getItem('hasSeenModal') == 'true';
        if (isMobile && !hasSeenModal) {
            setAppModalIsOpen(true);
            window.localStorage.setItem('hasSeenModal', 'true');
        }
    }, []);
    var customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            backgroundColor: 'white',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '270px',
            height: '300px',
        },
    };
    var closeModal = function () {
        setAppModalIsOpen(false);
    };
    var handleDownload = function () {
        var downloadLink = iosDevice ? DOWNLOAD_APP_LINK_APPLE : DOWNLOAD_APP_LINK_ANDROID;
        window.open(downloadLink, '_blank');
    };
    var iosDevice = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod',]
        .includes(navigator.platform) ||
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    var AppModal = function () {
        return (React.createElement(Modal, { isOpen: appModalIsOpen, onRequestClose: closeModal, style: customStyles },
            React.createElement("p", { style: { margin: '40px 0' } }, APP_MODAL.message),
            React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } },
                React.createElement(Button, { onClick: handleDownload }, APP_MODAL.successMessage)),
            React.createElement("div", { style: { display: 'flex', justifyContent: 'center', marginTop: '20px' } },
                React.createElement(Button, { onClick: closeModal }, APP_MODAL.failMessage))));
    };
    return (React.createElement(AdminLayout, null,
        isContact && (React.createElement(PaymentModal, { isOpen: isOpenModal, setIsOpen: setIsOpenModal, id: selectedReceipt })),
        name && (React.createElement(Title, null,
            WELCOME,
            " ",
            name)),
        React.createElement(Container, null,
            React.createElement(AppModal, null),
            isContact ? (React.createElement(React.Fragment, null,
                React.createElement(Alerts, { newAlerts: newAlerts, oldAlerts: oldAlerts, isLoading: isLoadingAlerts }),
                React.createElement(TopContainer, null,
                    React.createElement(DashboardCard, { data: settlements, title: SETTELMENTS, linkTo: function () { return history.push(ROUTES.settlement); } }),
                    React.createElement(DashboardCard, { data: pendingExpenses, title: RECEIPTS, isCommonExpenses: true, setSelectedReceipt: setSelectedReceipt, setIsOpenModal: setIsOpenModal, linkTo: function () { return history.push(ROUTES.receipt); } })))) : isSuperAdmin ? (React.createElement(SuperAdminDashboard, null)) : (React.createElement(AdminDashboard, { buildings: buildings })))));
};
export default HomeAdminPage;
